import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Head from 'Components/core/Head';

export default class PublicMobileApp extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return (
            <StyledComponent
                className="component-mobile-app"
                styles={require('./styles')}
            >
                <Head
                    title="Aplikacja FitAdept"
                    description="Dołącz do społeczności FitAdept"
                />
                <LayoutContainer>
                    <div className="mock">
                        <img className="mock-image" src={require('Theme/images/mobike-mock.png')} />
                        <div className="submock">
                            <h1 className="headline">
                                Pobierz aplikację FitAdept
                            </h1>

                            <div className="links">
                                <a className="link gplay" href="https://play.google.com/store/apps/details?id=com.fitadept.light">
                                    <img className="link-image" src={require('Theme/images/googleplay-download.png')} />
                                </a>
                                <a className="link appstore" href="https://apps.apple.com/us/app/fitadeptapp/id1484566229?ls=1">
                                    <img className="link-image" src={require('Theme/images/appstore-download.png')} />
                                </a>
                            </div>
                        </div>
                    </div>
                </LayoutContainer>

                <div className="image-bg" />
            </StyledComponent>
        );
    }
}
