import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 60vh;

        .image-bg {
            background-image: url(${require('Theme/images/couponRegister/bg.jpg')});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            z-index: 0;
        }

        .mock {
            position: relative;
            z-index: 1;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .mock-image {
                position: relative;
                z-index: 1;
                height: 80vh;
                width: auto;
                margin: 0 auto;
                transform: translateX(-50%);
                margin-left: 50%;
            }

            .submock {
                position: absolute;
                top: 70%;
                z-index: 1;

                .headline {
                    margin-top: 1em;
                    line-height: 100%;
                    color: white;
                    font-size: 2em;
                    text-align: center;

                    .sub {
                        font-size: 80%;
                        font-weight: 300;
                        display: block;
                    }
                }

                .links {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .link {
                        display: block;
                        margin: 0 .5em;

                        &.gplay {
                            .link-image {
                                width: 15em;
                                max-width: 100%;
                            }
                        }
                        &.appstore {
                            .link-image {
                                width: 13em;
                                max-width: 95%;
                            }
                        }
                    }
                }
            }
        }

    `;

